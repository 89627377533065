jQuery(document).ready(function($) {
	$('.bis-wrapper:not(.one-slide) .bis-slider-wrapper').slick({
		autoplay: true,
		autoplaySpeed: 6000,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
		arrows: false,
		dots: true,
	});

}); /* end of as page load scripts */
